<template>
    <section v-if="banners.length > 0" class="box-section">
        <div class="container-main" :style="{'background-color': backColor}" @click="closeSections()">
            <div class="container-banner">
                <carousel class="carusel-banner" @paginationClick="listen(banner)" @page-change="listen"
                          :navigationEnabled="false" paginationPosition="bottom-overlay"
                          :paginationSize="15" paginationColor="#FFFFFF" paginationActiveColor="#2A5597"
                          :paginationEnabled="true" :perPage="1" :centerMode="true" :loop="true"
                          :autoplay="true" :autoplayTimeout=5000>
                    <slide @slideclick="goToSection(banner)" v-for="(banner, index) in banners" :key="index"
                           v-if="banner.values.banner_imagen.value">
                        <img v-if="!isPhone" :loading="index === 0 ? 'eager' : 'lazy'" class="d-md-block img-banner"
                             :src="`${banner.values.banner_imagen.value.public_path}`"
                             :alt="banner.values.banner_imagen.value.alt"
                             width="1400"
                             height="427"
                             :fetchpriority="index === 0 ? 'high' : 'low'">
                        <img v-else :loading="index === 0 ? 'eager' : 'lazy'" class="d-block img-banner"
                             :src="`${banner.values.banner_imagen.value.public_path}?size=mobile`"
                             :alt="banner.values.banner_imagen.value.alt"
                             width="411"
                             height="377"
                             :fetchpriority="index === 0 ? 'high' : 'low'">
                    </slide>
                </carousel>
            </div>
        </div>
    </section>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';

export default {
    components: {
        Carousel, Slide,
    },
    props: {bannersData: Array},
    data() {
        return {
            isReady: false,
            backColor: '#ffffff',
            number: 0,
            isPhone: false,
            isSomeSectionOpen: false,
            banners: []
        }
    },
    beforeMount() {
        this.banners = this.getBanners()
    },
    mounted() {
        window.addEventListener('resize', this.getDeviceType)
        this.getDeviceType();
        this.listen(0)
    },
    created() {
        this.$bus.$on('emit-section-open', (payload) => {
            this.isSomeSectionOpen = payload;
        })
    },
    methods: {
        goToSection(data) {
            if (data.values.banner_url.value !== '' && data.values.banner_url.value != null && !this.isSomeSectionOpen) {
                dataLayer.push({
                    'event': 'select_promotion',
                    'site': {
                        'items': [
                            {
                                'slug': data.slug,
                                'index': this.banners.indexOf(data)
                            }
                        ]
                    }
                })
                window.open(`${data.values.banner_url.value}`, '_blank');
            } else {
                this.$bus.$emit('close-all-section');
            }
        },
        listen(payload) {
            this.backColor = this.banners[payload].values['seccion_cta_color'].value ? this.banners[payload].values['seccion_cta_color'].value : '#ff8618';
            dataLayer.push({
                'event': 'promotion_view',
                'site': {
                    'items': [
                        {
                            'slug': this.banners[payload].slug,
                            'index': payload
                        }
                    ]
                }
            })
        },
        getBack() {
            return this.banners[0].values['seccion_cta_color'].value;
        },
        closeSections() {
            this.$bus.$emit('close-all-section');
        },
        getDeviceType() {
            this.keyComponent = window.innerWidth;
            this.isPhone = window.innerWidth <= 550;
            setTimeout(() => {
                this.isReady = true;
            }, 2000);
        },
        getBanners() {
            return  this.bannersData.filter((item) => {
                if (item.values?.mostrar_hasta?.value !== null && item.values?.banner_imagen?.value !== null)
                    return this.$moment().isBefore(item.values.mostrar_hasta.value)

                return item.values?.banner_imagen?.value !== null;
            })
        }
    }

}
</script>

<style lang="scss">

.VueCarousel-dot {
    margin: 15px 8px 0 !important;
    padding: 0 !important;
    background-color: rgb(0, 0, 0);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 40%);

    &:focus {
        outline: 0 !important;
        border: 0;
    }
}

.container-main {
    width: 100%;
}

.container-banner {
    margin: 0 auto 20px;
    width: 100%;
    height: auto;
    max-width: 1400px;
    @media screen and (max-width: 575px) {
        margin: 0 auto 30px;
    }
}

.carusel-banner {
    min-height: min-content !important;
}

.VueCarousel-pagination {
    bottom: -32px !important;
    display: block;
    width: 100% !important;
    margin: 0;
    z-index: 999;

    .VueCarousel-dot-container {
        margin-top: 0 !important;
    }
}
</style>