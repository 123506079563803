
<section v-if="banners.length > 0" class="box-section">
    <div class="container-main" :style="{'background-color': backColor}" @click="closeSections()">
        <div class="container-banner">
            <carousel class="carusel-banner" @paginationClick="listen(banner)" @page-change="listen"
                      :navigationEnabled="false" paginationPosition="bottom-overlay"
                      :paginationSize="15" paginationColor="#FFFFFF" paginationActiveColor="#2A5597"
                      :paginationEnabled="true" :perPage="1" :centerMode="true" :loop="true"
                      :autoplay="true" :autoplayTimeout=5000>
                <slide @slideclick="goToSection(banner)" v-for="(banner, index) in banners" :key="index"
                       v-if="banner.values.banner_imagen.value">
                    <img v-if="!isPhone" :loading="index === 0 ? 'eager' : 'lazy'" class="d-md-block img-banner"
                         :src="`${banner.values.banner_imagen.value.public_path}`"
                         :alt="banner.values.banner_imagen.value.alt"
                         width="1400"
                         height="427"
                         :fetchpriority="index === 0 ? 'high' : 'low'">
                    <img v-else :loading="index === 0 ? 'eager' : 'lazy'" class="d-block img-banner"
                         :src="`${banner.values.banner_imagen.value.public_path}?size=mobile`"
                         :alt="banner.values.banner_imagen.value.alt"
                         width="411"
                         height="377"
                         :fetchpriority="index === 0 ? 'high' : 'low'">
                </slide>
            </carousel>
        </div>
    </div>
</section>
